import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import i18n from "../utils/i18n";

import ContactNetwork from "@/views/ContactNetwork.vue";
import EvContract from "@/views/EvContract.vue";
import EvError from "@/views/EvError.vue";
import EvShare from "@/views/EvShare.vue";
import EvStake from "@/views/EvStake.vue";
import EvStakePower from "@/views/EvStakePower.vue";
import EvTeam from "@/views/EvTeam.vue";
import EvWatchOnlyWallet from "@/views/EvWatchOnlyWallet.vue";
import EvWatchOnlyWalletAdd from "@/views/EvWatchOnlyWalletAdd.vue";
import SelectNetwork from "@/views/SelectNetwork.vue";
import EvWithdraw from "@/views/EvWithdraw.vue";
import EvHome from "@/views/EvHome.vue";
import EvEnvironment from "@/views/EvEnvironment.vue";
import EvPrivate from "@/views/EvPrivate.vue";
import EvOther from "@/views/EvOther.vue";
import EvWatch from "@/views/EvWatch.vue";
import test from "@/views/test.vue";
import EvWatchList from "@/views/EvWatchList.vue";
import FlashLoans from "@/views/FlashLoans.vue";
import EvFalocn from "@/views/EvFalocn.vue";
import EvFalocnV3 from "@/views/EvFalocnV3.vue";
import EvFalocnV4 from "@/views/EvFalocnV4.vue";
import EvSwap from "@/views/EvSwap.vue";
import EvFalocn2pro from "@/views/EvFalocn2pro.vue";
import FalconIdo from "@/views/FalconIdo.vue";
import FalconIdo2 from "@/views/FalconIdo2.vue";
import FalconIdo3 from "@/views/FalconIdo3.vue";
import FalconIdo4 from "@/views/FalconIdo4.vue";

import bazaar from "@/views/bazaar.vue";
import Tobelisted from "@/views/Tobelisted.vue";
import operation from "@/views/operation.vue";
import contractSquare from "@/views/contractSquare.vue";
import record from "@/views/record.vue";
import EvFalocnDALplan from "@/views/EvFalocnDALplan.vue";
import Liquidity from "@/views/Liquidity.vue";
import SuperMember from "@/views/SuperMember.vue";
import property from "@/views/property.vue";
import BalanceTreasure from "@/views/BalanceTreasure.vue";


Vue.use(VueRouter);
const router = new VueRouter({
  // mode: "history",
  routes: [
    {path: "/SelectNetwork", component: SelectNetwork},
    {path: "/", component: SelectNetwork},
    {path: "/EvError", component: EvError},
    {path: "/test", component: test},
    {path: "/EvEnvironment", component: EvEnvironment},
    {path: "/EvPrivate", component: EvPrivate},
    {
      path: "/subpage",
      component: EvOther,
      children: [
        {path: "/EvContract", component: EvContract},
        {path: "/EvWatch", component: EvWatch},
        {path: "/EvWatchList", component: EvWatchList},
        {path: "/FlashLoans", component: FlashLoans},
        {path: "/EvFalocn", component: EvFalocn},
        {path: "/EvFalocn2pro", component: EvFalocn2pro},
        {path: "/EvFalocnV3", component: EvFalocnV3},
        {path: "/EvFalocnV4", component: EvFalocnV4},
        {path: "/EvSwap", component: EvSwap},
        {path: "/FalconIdo", component: FalconIdo},
        {path: "/FalconIdo2", component: FalconIdo2},
        {path: "/FalconIdo3", component: FalconIdo3},
        {path: "/FalconIdo4", component: FalconIdo4},
        {path: "/EvFalocnDALplan", component: EvFalocnDALplan},
        {path: "/Liquidity", component: Liquidity},
        {path: "/SuperMember", component: SuperMember},
        {path: "/property", component: property},
        {path: "/BalanceTreasure", component: BalanceTreasure},


      ],
    },
    {
      path: "/home",
      component: EvHome,
      meta: {requiresPolygon: true},
      children: [
        {path: "/EvShare", component: EvShare},
        {path: "/EvStake", component: EvStake},
        {path: "/EvStakePower", component: EvStakePower},
        {path: "/staking", component: EvTeam},
        {path: "/EvTeam", component: EvTeam},
        {path: "/EvWatchOnlyWallet", component: EvWatchOnlyWallet},
        {path: "/EvWatchOnlyWalletAdd", component: EvWatchOnlyWalletAdd},
        {path: "/EvWithdraw", component: EvWithdraw},
        {path: "/ContactNetwork", component: ContactNetwork},
      ],
    },
    {
      path: "/bazaar",
      component: bazaar,
      meta: {requiresPolygon: true},
      children: [
        {path: "/Tobelisted", component: Tobelisted},
        {path: "/operation", component: operation},
        {path: "/contractSquare", component: contractSquare},
        {path: "/record", component: record},

      ],
    },
  ],
});

// Check if on Polygon Network
// function isOnPolygonNetwork() {
//   if (typeof window.ethereum !== "undefined") {
//     return window.ethereum.chainId === "0x89";
//   }
//   return false;
// }

router.beforeEach((to, from, next) => {
  const language = store.state.language;
  i18n.locale = language;

  // Polygon network check for all routes
  // if (!isOnPolygonNetwork()) {
  //   next("/SelectNetwork");
  // } else {
  //   next();
  // }

  next();
});

export default router;
